/**
  DO NOT MODIFY THIS FILE WITHOUT ALSO MODIFYING
  the `ThemeService` and/or `CSSThemeProperties`
  at `src\app\services\theme.service.types.ts`

  All CSS variables containing @{ant-prefix} MAY 
    be overridden by ThemeService

  All CSS variables containing @{theme-prefis} MUST either
    be defined by ThemeService (and have a `defaultValue`)
    OR
    have a default value in the `var()` LESS function
*/

@import "../node_modules/ng-zorro-antd/ng-zorro-antd.variable.less";
@import "./styles.scss";
@import "./environments/custom-styles.scss";

// #region SUPPLEMENTAL VARIABLIZED COLORS
/**
  Ng-Zorro provides dynamic themes via CSS variables; however,
  some themed elements are missing.

  We're defining them here by overriding values 
  from `node_modules\ng-zorro-antd\style\themes\variable.less`

*/
@theme-prefix: "txb";
@layout-sider-background: ~'var(--txb-layout-sider-background)';
@menu-dark-bg: ~'var(--txb-layout-sider-background)';
@menu-dark-inline-submenu-bg: ~'var(--txb-layout-sider-submenu-bg)';
@backdrop-color: ~'var(--txb-backdrop-color)';


// #endregion SUPPLEMENTAL VARIABLIZED COLORS

// #region ANT CLASS OVERRIDES
/*
  Generally, we should not be overriding ant- prefixed CSS classes if at all possible; 
  however, not all components offer a variablized input for the style granularity 
  we want to achieve, so we'll do the overrides here.
  
  NOTE: some compoents may override ant- prefixed CSS classes more granularly
  using the ::ng-deep CSS directive 
*/

.@{drawer-prefix-cls}, .@{dialog-prefix-cls} {
  &-header{
    background: ~'var(--txb-header-bg)';
    border-radius:0px;
  }
  &-title {
    color: ~'var(--txb-header-fg)';
  }
}

nz-sider, nz-sider .ant-menu-dark {

  /** 
    Ensure Sider menu items are readable
  */

  .ant-menu-item, .ant-menu-submenu-title {
    color: ~'var(--txb-layout-sider-foreground)' !important;
    :hover {
      color: ~'var(--txb-layout-sider-foreground-hover)' !important;
    }
  }
  .ant-menu-submenu-arrow{
    ::before, ::after {
      background-color: ~'var(--txb-layout-sider-foreground)' !important;
      :hover {
        background-color: ~'var(--txb-layout-sider-foreground-hover)' !important;
      }
    }
  }
  .ant-menu-item-selected {
    color: ~'var(--txb-layout-sider-selected-foreground)' !important;
    :hover {
      color: ~'var(--txb-layout-sider-selected-foreground-hover)' !important;
    }
  }
  // Ensure the status selector is readable
  .ant-badge-status-text {
    color: ~'var(--txb-layout-sider-foreground)' !important;
  }
}


// #endregion  ANT CLASS OVERRIDES

// #region APP COMPONENT STYLES
/* 
  All custom CSS classes that require either a CSS or a LESS variable should be 
  defined below.

  Any CSS Classes that do not need a a CSS or a LESS variable should be defined in 
  styles.scss

*/

#logout-list {
  .ant-menu-item {
    color:  ~'var(--txb-layout-sider-logout-fg)' !important;
    background: ~'var(--txb-layout-sider-logout-bg)' !important;
  }

  .ant-menu-item:hover {
    color:  ~'var(--txb-layout-sider-logout-fg-hover)' !important;
    background: ~'var(--txb-layout-sider-logout-bg-hover)' !important;
  }
}

app-number-switcher .full-name {
  color: ~'var(--txb-layout-sider-foreground)';
}

.guideButton{
  font-size: 16px;
  color: ~'var(--@{ant-prefix}-primary-color)';
  vertical-align: text-bottom;
}

.guideButton:hover{
  color: ~'var(--@{ant-prefix}-primary-color-hover)';
  font-size: 19px
}

.msg-out {
  color: ~'var(--txb-message-out-fg)';
  background-color: ~'var(--txb-message-out-bg)';
}

.msg-out .linkyClass{
  color: ~'var(--txb-message-out-link)' !important;
}

.msg-in {
  color:~'var(--txb-message-in-fg)';
  background-color: ~'var(--txb-message-in-bg)';
}

.msg-in .linkyClass{
  color: ~'var(--txb-message-in-link)' !important;
}

.@{drawer-prefix-cls} {
  &-header{
    background: ~'var(--txb-header-bg, var(--@{ant-prefix}-primary-color))';
    border-radius:0px;
  }
}

.txb-list >  li:hover, .txb-list > .list-item:hover, nz-table.txb-list tr.ant-table-row:hover>td {
  background-color: ~'var(--txb-item-hover-bg)';
  color: ~'var(--txb-item-hover-fg)'; 
}

.list-item-active {
  background-color:~'var(--txb-list-item-active-bg)';
  color: ~'var(--txb-list-item-active-fg)';
  :hover *{
    background-color:~'var(--txb-list-item-active-bg-hover)';
    color: ~'var(--txb-list-item-active-fg-hover)';
  }
 
}

// #endregion APP COMPONENT STYLES

// #region APP UTILITY CLASSES
.backdropcolor-background { 
  background-color: @backdrop-color;
}


.app-view-two-pane>nz-card .right-pane {
  background-color: @backdrop-color;
}

.reminder-dot nz-badge-sup { 
  background-color: ~'var(--@{ant-prefix}-primary-color)';
}

// #endregion
