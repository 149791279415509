/*
    DO NOT ADD ANY THEMEABLE ITEMS TO THIS FILE
    Any CSS style that could be "Themed" should be defined in `src\styles.less`
*/
@import '~@ctrl/ngx-emoji-mart/picker';

// #region BEGIN ANT STYLE OVERRIDES
.ant-modal-body {
    max-height: calc(100vh - 120px - 55px); // 120px is the top and bottom "margin" of ant-modal, and 55px is the approximate height of the modal header
    overflow: auto;
}

.ant-modal { 
    max-height: 100vh;
    overflow: hidden;
}

.ant-drawer-title, .ant-modal-title{
    color:#fff;
}

.ant-transfer-list{
    width:45% !important;
}

.ant-badge-status-text {
    margin-left: 8px;
    color: rgb(255, 255, 255);
    font-size: 11px;
}

.ant-table-placeholder{
    padding: 0px
}

.ant-table-placeholder{
    padding: 0px
}

.ant-table-filter-dropdown .ant-dropdown-menu{
    overflow:auto !important;
    height:200px !important;
}

.ant-table-pagination{
    padding-right: 20px;
}

.ant-time-picker-panel-input{
    pointer-events: none;
}

nz-select:not(.ant-picker-calendar-month-select):not(.ant-picker-calendar-year-select)  {
    width: 100%
}


// #endregion ANT STYLE OVERRIDES


// #region app-view layout styles

.app-view {
    display: block;
    height: 100vh;
}

.app-view>nz-card {
    height: 100%;
    overflow: hidden;
}

.app-view > nz-card > .ant-card-head{
    max-height: 60px;
    min-height: 60px;
    margin-bottom: 0px;
}

.app-view>nz-card > .ant-card-body{
    padding:0 !important;
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    overflow: auto;
}


.app-view>nz-card > .ant-card-body > .ant-row{
    height: 100%
}

.app-view nz-tabset .ant-tabs-nav {
    padding-left: 20px;
    padding-right: 20px;
}

.app-view-one-pane div.pad-main-content, .app-view-one-pane nz-tabset.pad-main-content .ant-tabs-content-holder {
    padding:20px;
}

.app-view-two-pane>nz-card .left-pane {
    flex: 2 1 0%;
    height: 100%;
    overflow-y: auto;
}

.app-view-two-pane>nz-card .right-pane {
    flex: 5 1 0%;
    margin:0;
    padding:0;
    height:100%;
}

.app-view-two-pane>nz-card .left-pane li:hover {
    cursor:pointer; // remove
}
// #endregion app-view layout styles

// #region "list" styling (div, ul and nz-table elements) 
ul.txb-list {
    list-style:none;
    margin:0;
    padding:0;
}

ul.txb-list-max-height {
    list-style:none;
    margin:0;
    padding:0;
    max-height: 500px;
}

.txb-list > li {
    padding:10px;
    border-bottom:1px solid #eee;
}

.txb-list > li > small {
    color:#bbb;
    line-height:14px;
}


.list-item{
    border-bottom:1px solid rgba(0,0,0,.2);
    width:100%;
    align-items: center;
}

.list-item-single-select:hover{
    cursor:pointer;
}

.list-item-active a{
    color:#fff;
}

// #endregion "list" styling

// #region APP COMPONENT STYLES
/* 
  All custom CSS classes that require either a CSS or a LESS variable should be 
  defined below.

  Any CSS Classes that do not need a a CSS or a LESS variable should be defined in 
  styles.scss
*/

.drawer-button{
    margin-bottom:12px;
    margin-right:12px;
}

.loginBody{
    background-image: -webkit-linear-gradient(
        180deg
        , rgb(56, 182, 255) 0%, rgb(33, 173, 255) 100%);
    height:100vh;
}

.msg-in .linkyClass{
    text-decoration: underline;
}

.msg-out .linkyClass{
    text-decoration: underline;
}

markdown{
    img{
        max-width:650px;
        border:1px solid #fff;
        box-shadow:3px 3px 10px 3px rgba(0,0,0,0.1) !important;
        margin:10px 0;
    }
    
    blockquote{
        border-left:3px solid rgba(0,0,0,.4);
        background:rgba(0, 174, 255, 0.05);
        padding: 5px 0px;
        padding-left:10px;
    }

    ul li, ol li{
        margin: 1em 0;
    }
}

.integration-form-label {
  min-width: 185px;
}

.debug-data-viewer  .ant-popover-content { 
  margin-bottom: 50px;
}

.debug-data-viewer  .ant-popover-inner-content { 
  width: 80vw; 
  height: 60vh;
  max-width: 80vw; 
  max-height: 60vh;
  overflow: hidden;
}

.debug-data-viewer .ant-tabs-content-holder{ 
  max-height: 100%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}

.debug-data-viewer nz-tabset{ 
  max-width: 100%;
  max-height: 100%;
}



.reminder-dot > nz-badge-sup { 
    /*
     Fixes a bug where the date number is translated "up" 
     when there's a reminder dot c compared to the other
     date numbers
    */
    position: absolute !important;
    // Ensure the reminder dot is displayed to the top-right of the date number
    transform: translate(8px, -16px) !important;
}

// #endregion APP COMPONENT STYLES

// #region UTILITY CLASSES
.border-radius-10px{
    border-radius: 10px;
}
.pull-right{
    float:right;
}

.pull-left{
    float:left;
}

.text-center{
    text-align:center;
}

.text-left{
    text-align:left;
}

.text-right{
    text-align:right;
}

.pad-10{
    padding:10px;
}

.pad-15{
    padding:15px;
}

.pad-20{
    padding:20px;
}

.pad-25{
    padding:25px;
}

.far{
    margin-right:10px;
}

.muted{
    color:#666;
}

.flex-end{
    display: flex;
    justify-content: flex-end;
}

.inset-panel{
    padding:1em;
    border:1px solid #bbb;
    background:#efefef;
    border-radius:.25em;
}

// #endregion UTILITY CLASSES